<template>
  <el-container>
    <el-aside width="auto">
      <slot name="aside" />
    </el-aside>
    <el-main>
      <slot />
    </el-main>
  </el-container>
</template>

<style scoped>
.el-container {
  height: 100%;
}
</style>
