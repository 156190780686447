import { ProjectRepository } from '@/repositories/ProjectRepository'
import { Project } from '@/types/project'
import { ref, Ref, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'

type Response = {
  projectName: Ref<string>
  notFound: Ref<boolean>
}

export const useProject: Function = (): Response => {
  const projectName: Ref<string> = ref<string>('');
  const notFound: Ref<boolean> = ref<boolean>(false);
  const route = useRoute();
  const repository = new ProjectRepository();

  const updateProject = async (projectId: number): Promise<void> => {
    try {
      const project: Project = await repository.get(projectId) // TODO 見つからなければ404画面を表示したい
      projectName.value = project.name;
    } catch (e) {
      notFound.value = true;
    }
  };

  watch(() => route.params.project_id, (projectId): void => {
    if (projectId) {
      updateProject(Number(projectId));
    }
  })

  onMounted((): void => {
    updateProject(Number(route.params.project_id));
  });

  return {
    projectName,
    notFound,
  };
};
